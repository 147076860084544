import { cva } from 'class-variance-authority';

export const cvaVoting = cva([
  'Voting-cvaVoting',
  'flex flex-col',
  'flex-1',
  'md:h-auto h-full',
]);

export const cvaHeaderTitle = cva([
  'Voting-cvaHeaderTitle',
  'text-cWhite text-2.8-800 md-max:text-2.4 md-max:font-semibold sm-max:text-2.2 sm-max:font-bold',
  'mb-1.2 md-max:mb-0.8 sm-max:mb-0',
]);

export const cvaVotingInner = cva([
  'Voting-cvaVotingInner',
  'flex flex-col items-center',
  'flex-1',
  'md:h-auto h-full',
]);

export const cvaVotingGrid = cva(
  [
    'Voting-cvaVotingGrid',
    'relative',
    'w-full',
    'grid gap-1.2 md-max:gap-0.6 sm-max:gap-0.1',
    'mb-4.8 md-max:mb-2.4',
    'rounded-[2.4rem] md-max:rounded-[1.6rem]',
    'overflow-hidden',
  ],
  {
    variants: {
      isColumn: {
        true: 'flex-1 grid-rows-[minmax(0,1fr)_auto_minmax(0,1fr)] md-max:max-h-[60.2rem]',
        false: 'grid-cols-1a1',
      },
    },
  }
);

export const cvaVotingGridIcon = cva(
  [
    'Voting-cvaVotingGridIcon',
    'z-10 pointer-events-none relative',
    'flex items-center',
    'duration-300',
  ],
  {
    variants: {
      isHover: {
        true: 'scale-[1.5] sm-max:scale-[3]',
        false: 'scale-100',
      },
    },
  }
);

export const cvaVotingHoverIcon = cva(
  ['Voting-cvaVotingInnerIcon', 'absolute-center z-[1] duration-300'],
  {
    variants: {
      isHover: {
        true: ['opacity-100'],
        false: ['opacity-0'],
      },
    },
  }
);

export const cvaInnerIcon = cva(
  [
    'Voting-cvaInnerIcon',
    'block',
    'w-8 h-8 md-max:w-5 md-max:h-5 sm-max:w-3.2 sm-max:h-3.2',
    'duration-300',
    '[&_svg]:w-full [&_svg]:h-full ',
  ],
  {
    variants: {
      isHover: {
        left: [
          '-translate-x-[15rem] translate-y-5 rotate-[-15deg] md-max:-translate-x-6 md-max:translate-y-2',
        ],
        right: [
          'translate-x-[15rem] translate-y-5 rotate-[15deg] md-max:translate-x-6 md-max:translate-y-2',
        ],
        top: ['-translate-y-3.2 sm-max:-translate-y-2.2'],
        bottom: ['translate-y-3.2 sm-max:translate-y-2.2'],
      },
    },
  }
);

export const cvaVotingGridLoaderWrapper = cva([
  'Voting-cvaVotingGridLoaderWrapper',
  'absolute top-0 left-0',
  'h-full w-full',
]);

export const cvaVotingGridLoader = cva([
  'Voting-cvaVotingGridLoader',
  'absolute top-0 left-0',
  'bg-voting-loader',
  'h-full w-full',
  'animate-voting',
]);
