'use client';
import Container from '@/shared/ui/Container/ui/Container';
import clsx from 'clsx';
import { FC, useLayoutEffect, useMemo, useState } from 'react';
import DislikeIcon from '~/icons/dislike.svg';
import VotingImage from './VotingImage';
import {
  cvaInnerIcon,
  cvaVoting,
  cvaVotingGrid,
  cvaVotingGridIcon,
  cvaVotingHoverIcon,
  cvaVotingInner,
} from './VotingStyles';
import { handleRequireAuth } from '@/shared/utils/handleRequireAuth';
import useQueryParam from '@/shared/hooks/useQueryParam';
import { useVote } from '@/shared/hooks/useVote';
import { VotingOver } from '@/widgets/Voting/ui/VotingOver';
import VotingContestIcon from '~/icons/voting-contest.svg';
import VotingIcon from '~/icons/voting.svg';
import { useGetPhotoCategories } from '@/shared/hooks/useGetPhotoCategories';
import { VotingHeader } from '@/widgets/Voting/ui/VotingHeader';
import VotingContestHoverIcon from '~/icons/voting-contest-hover.svg';
import VotingHoverIcon from '~/icons/voting-hover.svg';
import { useVotingTitle } from '@/widgets/Voting/hooks/useVotingTitle';
import { useLocale } from 'next-intl';
import { useGetHomePage } from '@/shared/hooks/useGetHomePage';
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from '@/widgets/Voting/utils/consts';
import { useMediaValues } from '@/shared/hooks/useMediaValues';
import { cva } from 'class-variance-authority';

interface VotingProps {
  className?: string;
}

const Voting: FC<VotingProps> = ({ className }) => {
  const locale = useLocale();

  const { data: homePage } = useGetHomePage({ lang: locale });

  // console.log(homePage)

  const [category] = useQueryParam<string | undefined>('category');

  const { data: contestCategories } = useGetPhotoCategories(
    {
      lang: locale,
    },
    {
      type: 'contest',
    }
  );

  const contestCategory = useMemo(() => {
    return (contestCategories ?? []).find(
      (i) => !Array.isArray(i.contest) && String(i.id) === category
    );
  }, [category, contestCategories]);

  const {
    query: { data: images, isFetching },
    mutation: vote,
    isOver,
  } = useVote({
    category_id: category ? Number.parseInt(category) : undefined,
    lang: locale,
  });

  const [isLoadingFirst, setIsLoadingFirst] = useState(false);
  const [isLoadingSecond, setIsLoadingSecond] = useState(false);
  const isLoading = isLoadingFirst || isLoadingSecond || isFetching;

  const [sizes1, setSizes1] = useState({
    width: DEFAULT_WIDTH,
    height: DEFAULT_HEIGHT,
  });
  const [sizes2, setSizes2] = useState({
    width: DEFAULT_WIDTH,
    height: DEFAULT_HEIGHT,
  });

  useLayoutEffect(() => {
    if (!isLoading && images) {
      setSizes1({
        width: images?.[0].thumbnail[1] ?? DEFAULT_WIDTH,
        height: images?.[0].thumbnail[2] ?? DEFAULT_HEIGHT,
      });
      setSizes2({
        width: images?.[1].thumbnail[1] ?? DEFAULT_WIDTH,
        height: images?.[1].thumbnail[2] ?? DEFAULT_HEIGHT,
      });
    }
  }, [isLoading]);

  const [enteredImageIndex, setEnteredImageIndex] = useState(0);

  const isColumn = useMediaValues(
    {
      0: true,
      768:
        sizes1.width / sizes1.height > 16 / 10 ||
        sizes2.width / sizes2.height > 16 / 10,
      1280: false,
    },
    1280,
    [sizes1.width, sizes1.height, sizes2.width, sizes2.height]
  );

  const hoverVariant = {
    0: undefined,
    1: isColumn ? ('top' as const) : ('left' as const),
    2: isColumn ? ('bottom' as const) : ('right' as const),
  }[enteredImageIndex];

  const { title, onAfterVote } = useVotingTitle({
    contestCategory,
  });

  const onVote = (
    photoWinnerId: number,
    photoLoserId: number,
    specialContestId?: number,
    isDraw?: boolean
  ) => {
    if (!isDraw) {
      const index = images?.findIndex((i) => i.id === photoWinnerId) ?? -1;
      setEnteredImageIndex(index === -1 ? 0 : index + 1);
    }

    handleRequireAuth(() => {
      if (!vote.isPending) {
        vote.mutate(
          {
            category_id: category ? Number.parseInt(category) : undefined,
            photo_winner_id: photoWinnerId,
            photo_loser_id: photoLoserId,
            is_draw: isDraw,
            special_contest_id: specialContestId,
            lang: locale,
          },
          {
            onSuccess: () => {
              onAfterVote();
              setEnteredImageIndex(0);
              setIsLoadingFirst(true);
              setIsLoadingSecond(true);
            },
          }
        );
      }
    });
  };

  if (isOver) {
    return <VotingOver />;
  }

  // console.log(images)

  return (
    <div className={clsx(cvaVoting(), className)}>
      <Container className={cvaVotingInner()} size={'xl'} gutters={'lg'}>
        <VotingHeader titleSlot={title} contestCategory={contestCategory} />
        <div className={cvaVotingGrid({ isColumn })}>
          <VotingImage
            voteItem={images?.[0]}
            isColumn={isColumn}
            isLoading={isLoading}
            sizes={sizes1}
            side={'left'}
            onMouseEnter={() => setEnteredImageIndex(1)}
            onMouseLeave={() => setEnteredImageIndex(0)}
            onLoadImage={() => setIsLoadingFirst(false)}
            onClick={() =>
              images?.length &&
              onVote(
                images[0].id,
                images[1].id,
                images[0].special_contest_id
                  ? images[0].special_contest_id
                  : undefined
              )
            }
          />
          <div
            className={cvaVotingGridIcon({
              isHover: Boolean(enteredImageIndex),
            })}>
            <span className={'absolute-center'}>
              <span
                className={cvaInnerIcon({
                  isHover: hoverVariant,
                })}>
                {contestCategory ? <VotingContestIcon /> : <VotingIcon />}
              </span>
            </span>
            <span
              className={clsx(
                cvaVotingHoverIcon({ isHover: Boolean(enteredImageIndex) })
              )}>
              <span
                className={cvaInnerIcon({
                  isHover: hoverVariant,
                  className: 'scale-120',
                })}>
                {contestCategory ? (
                  <VotingContestHoverIcon />
                ) : (
                  <VotingHoverIcon />
                )}
              </span>
            </span>
          </div>
          <VotingImage
            voteItem={images?.[1]}
            isColumn={isColumn}
            side={'right'}
            isLoading={isLoading}
            sizes={sizes2}
            onLoadImage={() => setIsLoadingSecond(false)}
            onMouseEnter={() => setEnteredImageIndex(2)}
            onMouseLeave={() => setEnteredImageIndex(0)}
            onClick={() =>
              images?.length &&
              onVote(
                images[1].id,
                images[0].id,
                images[1].special_contest_id
                  ? images[1].special_contest_id
                  : undefined
              )
            }
          />
        </div>
        {images?.length ? (
          <button
            className={cvaDislikeButton()}
            type={'button'}
            onClick={() => onVote(images[0].id, images[1].id, undefined, true)}>
            <DislikeIcon className={'w-2.4 h-2.4'} />
            {homePage?.acf.button_text ?? ''}
          </button>
        ) : null}
      </Container>
    </div>
  );
};

const cvaDislikeButton = cva([
  'Voting-cvaDislikeButton',
  'inline-flex items-center justify-center gap-0.8 md-max:gap-0.4',
  'p-1 pr-1.8 md-max:p-0.6 md-max:pr-1',
  'border-2 border-cGray700 rounded-button',
  'text-cWhite text-1.6-600 sm-max:text-1.2-500',
  'bg-transparent',
]);

export default Voting;
