import { AxiosError, isAxiosError } from 'axios';
import { toast } from 'react-toastify';
import {
  isServer,
  MutationCache,
  QueryCache,
  QueryClient,
} from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { useTranslations } from 'next-intl';
import { TResponse } from '@/shared/api/axios';
import { persistOptions } from '@/shared/lib/cache';

export interface MakeQueryClient {
  errorsTranslations: ReturnType<typeof useTranslations<'errors'>>;
}
let browserQueryClient: QueryClient | undefined = undefined;

export function getQueryClient(
  errorsTranslations: MakeQueryClient['errorsTranslations']
) {
  if (isServer) {
    return makeQueryClient({ errorsTranslations });
  }

  if (!browserQueryClient) {
    browserQueryClient = makeQueryClient({ errorsTranslations });
    if (typeof window !== 'undefined') {
      persistQueryClient({
        queryClient: browserQueryClient,
        ...persistOptions,
      });
    }
  }
  return browserQueryClient;
}

export const makeQueryClient = (params: MakeQueryClient) => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // With SSR, we usually want to set some default staleTime
        // above 0 to avoid refetching immediately on the client
        staleTime: isServer ? Infinity : 0,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        retry: 2,
      },
    },
    queryCache: new QueryCache(makeQueryCacheConfig(params)),
    mutationCache: new MutationCache(makeMutationCacheConfig(params)),
  });
};

export const makeQueryCacheConfig = (
  params: MakeQueryClient
): NonNullable<ConstructorParameters<typeof QueryCache>[0]> => ({
  onSuccess: (data, query) => {
    if (query.meta?.onSuccess) query.meta.onSuccess(data, query);
  },
  onError: (error, query) => {
    if (!query.options.meta?.skipGlobalErrorHandler) {
      globalErrorHandler(error, params.errorsTranslations);
    }

    if (query.meta?.onError) query.meta.onError(error, query);
  },
  onSettled: (data, error, query) => {
    if (query.meta?.onSettled) query.meta.onSettled(data, error, query);
  },
});

export const makeMutationCacheConfig = (
  params: MakeQueryClient
): NonNullable<ConstructorParameters<typeof MutationCache>[0]> => ({
  onError: (error, _, __, mutation) => {
    if (!mutation.meta?.skipGlobalErrorHandler) {
      globalErrorHandler(error, params.errorsTranslations);
    }
  },
});

export const globalErrorHandler = (
  error: Error,
  errorsTranslations: MakeQueryClient['errorsTranslations']
) => {
  if (isAxiosError(error)) {
    const response = (
      error as AxiosError<
        TResponse<
          | string
          | Array<Record<string, string>>
          | Record<string, Record<string, string>>
        >
      >
    ).response?.data;
    let errorMessages: string[] = [];

    [response?.errors, response?.data, response?.result].some((data) => {
      if (data) {
        if (typeof data === 'string' && data.length) {
          errorMessages = [data];
          return true;
        } else if (
          typeof data === 'object' &&
          Array.isArray(data) &&
          data.length
        ) {
          errorMessages = (data as Array<Record<string, string>>)
            .map((errorObj) => Object.values(errorObj))
            .flat();
          return true;
        } else if (typeof data === 'object' && Object.keys(data).length > 0) {
          errorMessages = Object.values(
            data as Record<string, Record<string, string>>
          )
            .map((errorObj) => Object.values(errorObj))
            .flat();
          return true;
        }

        return false;
      } else {
        return false;
      }
    });

    if (errorMessages.length) {
      errorMessages.forEach((errorMessage) => toast(errorMessage));
    } else {
      toast(errorsTranslations('unknown_error'));
    }
  }
};
